<template>
	<v-content class="legal pa-3">
		<h1>特定商取引法に基づく表記</h1>
		<v-row class="list">
			<v-col cols="3">
				<h2>事業者情報</h2>
			</v-col>
			<v-col cols="9">
				<p>
					【事業者名】<br> {{legal.name}}
					<br> 【事業者の住所】
					<br> {{legal.address}}
					<br> 【電話番号】
					<br> {{legal.tel}}
					<br> 【メールアドレス】
					<br> {{legal.mail}}
					<br> 【責任者名】
					<br>{{legal.tantou}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>販売価格</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.price}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>対価以外に必要となる費用</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.otherfee}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>お支払方法</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.payment}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>商品購入方法</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.buy}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>商品の引渡時期</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.delivery_time}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>動作環境</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.operating}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>返品・キャンセル</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.cancel}}
				</p>
			</v-col>
		</v-row>
		<v-row class="list">
			<v-col cols="3">
				<h2>特別条件</h2>
			</v-col>
			<v-col cols="9">
				<p>
					{{legal.special}}
				</p>
			</v-col>
		</v-row>
	
	</v-content>
</template>

<script>
export default {
	data() {
		return {
			legal: {
				name: 'xxxxx',
				addresss: '',
				tel: '',
				mail: '',
				tantou: '',
				price: '各商品・サービスの価格は、アプリ内の購入ページに記載しています。',
				otherfee: 'アプリの利用に必要なインターネット接続環境、通信料金等はお客様の負担となります。',
				payment: '以下のいずれかのお支払方法をご利用いただけます。\n・各種クレジットカード',
				buy: 'アプリケーションの購入ページからお支払方法を選択し、「決済する」ボタンを押下し決済していただきますと、対象商品が購入できます。',
				delivery_time: '利用期限を設けている場合を除き、お支払い手続き完了後直ちにこ利用いただけます。 ',
				operating: '購入されたコンテンツをご利用いただくために必要な動作環境は、アプリストア内のアプリ紹介ページに表示しています。',
				cancel: 'デジタルコンテンツの特性上、購入確定後の返品 交換には応じられません。',
				special: '【クーリングオフについて】 \n特定商取引法に規定されているクーリングオフが適用されるサービスではありません。契約期間途中の解約となった場合も契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。この場合、サービスも契約満了日まで提供されます。'
			}
		}
	},
	async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')
    var shop_id = this.init.shop_id

    if(!shop_id){
      shop_id = this.init.sid
    }

    var req = { params:{
      'shop_id': shop_id,
    }}

    try {
      var res = await this.apiCall('/user/shop/detail', req)

      this.legal.name = res.shop.name.value
      this.legal.address = res.shop.address1.value
      this.legal.tel = res.shop.tel.value
      this.legal.mail = res.shop.email.value
      this.legal.tantou = res.shop.officer_name.value

      if (!res) return false;
    } catch(e) {
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
}
</script>

<style scoped>
.legal h1 {
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #333;
	margin-bottom: 30px;
}

.list h2 {
	font-weight: bold;
	font-size: 11px;
	line-height: 16px;
	color: #9d9d9d;
}

.list p {
	font-size: 13px;
	line-height: 20px;
	color: #333;
	margin-bottom: 0;
}

.list {
	border-bottom: solid 1px #DEDEDF;
}
</style>
